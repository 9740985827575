<template>
  <div class="content-wrapper view-queue-manage pt-3">
    <div class="content">
      <div class="row ml-0" style="max-width: 100%">
        <div
          id="calendarCol"
          class="col-12 col-xl-9 col-lg-8 col-md-7 order-2 order-md-1"
          style="overflow-x: auto"
        >
          <b-card no-body>
            <b-tabs card v-model="tabIndex">
              <b-tab no-body title="เรียงตามห้อง" @click="changeTab">
                <fullCalendar
                  ref="calendarRoom"
                  id="calendarRoom"
                  class="mt-2 mx-1"
                  defaultView="resourceTimeGridDay"
                  :themeSystem="themeSystem"
                  height="auto"
                  :defaultDate="dateDefault"
                  :header="header"
                  :titleFormat="titleFormat"
                  :minTime="openingTime"
                  :maxTime="closingTime"
                  :slotDuration="slotDuration"
                  :plugins="calendarPlugins"
                  timeZone="Asia/Bangkok"
                  :locale="locales"
                  :slotLabelFormat="slotLabelFormat"
                  :resources="roomList"
                  :events="appointmentList"
                  :editable="
                    isAccessMenu(['COUNTER', 'MANAGER', 'HEADCOUNTER'])
                  "
                  :selectable="
                    isAccessMenu(['COUNTER', 'MANAGER', 'HEADCOUNTER'])
                  "
                  :eventLimit="true"
                  :eventDurationEditable="true"
                  :nowIndicator="true"
                  :schedulerLicenseKey="schedulerLicenseKey"
                  :customButtons="customButtons"
                  :scrollTime="scrollTime"
                  :allDaySlot="false"
                  :handleWindowResize="true"
                  @windowResize="fcWindowResize"
                  @select="fcRoomResourceClick"
                  @eventClick="fcAppointmentClick"
                  @eventDrop="updateAppointment"
                  @eventResize="updateAppointment"
                  :eventRender="renderCalendarEvents"
                  :viewSkeletonRender="onViewSkeletonRender"
                ></fullCalendar>
              </b-tab>
              <b-tab no-body title="เรียงตามแพทย์" @click="changeTab">
                <fullCalendar
                  ref="calendarDoctor"
                  id="calendarDoctor"
                  class="mt-2 mx-1"
                  defaultView="resourceTimeGridDay"
                  :themeSystem="themeSystem"
                  height="auto"
                  :defaultDate="dateDefault"
                  :header="headerDoctors"
                  :titleFormat="titleFormat"
                  :minTime="openingTime"
                  :maxTime="closingTime"
                  :slotDuration="slotDuration"
                  :plugins="calendarPlugins"
                  timeZone="Asia/Bangkok"
                  :locale="locales"
                  :slotLabelFormat="slotLabelFormat"
                  resourceOrder="order"
                  :resources="doctorsList"
                  :events="appointmentListByDoctors"
                  :editable="
                    isAccessMenu(['COUNTER', 'MANAGER', 'HEADCOUNTER'])
                  "
                  :selectable="
                    isAccessMenu(['COUNTER', 'MANAGER', 'HEADCOUNTER'])
                  "
                  :eventLimit="true"
                  :eventDurationEditable="false"
                  :nowIndicator="true"
                  :schedulerLicenseKey="schedulerLicenseKey"
                  :customButtons="customButtons"
                  :scrollTime="scrollTime"
                  :allDaySlot="false"
                  :handleWindowResize="true"
                  @windowResize="fcWindowResize"
                  @select="fcDoctorResourceClick"
                  @eventClick="fcAppointmentDoctorClick"
                  @eventDrop="updateAppointment($event, 'doctor')"
                  :eventRender="renderCalendarEvents"
                  :viewSkeletonRender="onViewSkeletonRender"
                ></fullCalendar>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>

        <!-- month calendar -->
        <div class="col-xl-3 col-lg-4 col-md-5 order-1 order-md-2">
          <div class="h100-lg">
            <div class="table-sticky-top">
              <b-card no-body>
                <!-- ถ้ามี collaspe จะทำให้ table ไม่ sticky top -->
                <!-- <b-button
              class="m-2"
              size="sm"
              variant="light"
              @click="isCalendarOpen = !isCalendarOpen"
              >{{ isCalendarOpen ? "ปิดปฏิทิน" : "เปิดปฏิทิน" }}</b-button
                >-->
                <b-collapse id="queueMonthCalendar" v-model="isCalendarOpen">
                  <div class="row">
                    <div class="col-12">
                      <b-calendar
                        v-model="dateDefault"
                        class="border rounded p-2"
                        locale="th-TH"
                        :hide-header="true"
                        block
                      ></b-calendar>
                    </div>
                  </div>
                </b-collapse>
              </b-card>

              <!-- table queue payment -->
              <div class="row">
                <div class="col-12">
                  <!-- <h6>คิวรอชำระเงิน</h6> -->
                  <b-card no-body>
                    <b-table
                      id="paymentQueue"
                      class="mb-0 pointer-cursor"
                      small
                      hover
                      sort-icon-left
                      head-variant="light"
                      :items="tablePaymentList"
                      :fields="paymentQueueTableHeader"
                      @row-clicked="openPaymentModal"
                      :tbody-tr-class="rowClass + ' border-bottom'"
                    >
                      <template v-slot:cell(index)="data"
                        >{{ data.index + 1 }}.</template
                      >
                      <template v-slot:cell(treatmentTime)="data">{{
                        getTimeFormat(data.value)
                      }}</template>
                    </b-table>
                    <b-button
                      v-if="tablePaymentList.length != 0"
                      v-b-toggle.paymentQueueColorsDetail
                      size="sm"
                      class="m-3"
                      variant="outline-info"
                    >
                      <i class="fas fa-palette"></i>
                      ความหมายของสีในคิว
                    </b-button>
                    <div v-else class="text-muted text-center p-2">
                      ไม่มีคิวรอชำระ
                    </div>
                    <b-collapse id="paymentQueueColorsDetail" class>
                      <b-col>
                        <b-table-simple hover small caption-top>
                          <b-tr variant="purple">
                            <b-td class="text-center border-white rounded">
                              อยู่ในคิวรอชำระเงินนานกว่า
                              <strong>15 นาที</strong>
                            </b-td>
                          </b-tr>
                          <b-tr variant="red">
                            <b-td class="text-center border-white rounded">
                              อยู่ในคิวรอชำระเงิน
                              <strong>10-15 นาที</strong>
                            </b-td>
                          </b-tr>
                          <b-tr variant="yellow">
                            <b-td class="text-center border-white rounded">
                              อยู่ในคิวรอชำระเงิน
                              <strong>5-10 นาที</strong>
                            </b-td>
                          </b-tr>
                          <b-tr variant="green">
                            <b-td class="text-center border-white rounded">
                              อยู่ในคิวรอชำระเงินน้อยกว่า
                              <strong>5 นาที</strong>
                            </b-td>
                          </b-tr>
                        </b-table-simple>
                      </b-col>
                    </b-collapse>
                  </b-card>
                  <b-card no-body v-if="branchLinkedWithKtbPaotang">
                    <b-table
                      class="mb-0 pointer-cursor"
                      small
                      hover
                      sort-icon-left
                      head-variant="light"
                      show-empty
                      :items="paotangList"
                      :fields="paotangTableHeader"
                      @row-clicked="handlePaotangRowClicked"
                    >
                      <template v-slot:cell(index)="data"
                        >{{ data.index + 1 }}.</template
                      >
                      <template v-slot:cell(treatmentTime)="data">{{
                        getTimeFormat(data.value)
                      }}</template>
                      <template #empty>
                        <p class="text-muted text-center mb-0">
                          ไม่มีคิวชำระด้วยเป๋าตัง
                        </p>
                      </template>
                    </b-table>
                  </b-card>

                  <b-collapse id="ืqueueNotation" v-model="isCalendarOpen">
                    <div class="">
                      <AdsCard></AdsCard>
                    </div>
                    <!-- <b-card>
                      <div class="description text-muted">
                        <div class="mb-2">
                          -เมื่อคนไข้อยู่ในสถานะ
                          <strong>
                            <span
                              :style="{
                                color: 'white',
                                backgroundColor: '#ff708d',
                              }"
                              class
                              >รอเข้าตรวจ</span
                            >
                            /
                            <span
                              :style="{
                                color: 'white',
                                backgroundColor: '#fb8537',
                              }"
                              class
                              >รอชำระเงิน</span
                            >
                            /
                            <span
                              :style="{
                                color: 'white',
                                backgroundColor: '#998888',
                              }"
                              class
                              >ชำระเงินแล้ว</span
                            >
                          </strong>
                          จะไม่สามารถย้าย แก้ไขนัดหมาย หรือบันทึกการรักษาได้
                        </div>
                        <div class>
                          -ส่งตรวจ และบันทึกการรักษา ได้เฉพาะนัดหมายของ
                          <strong>"วันนี้"</strong>เท่านั้น
                        </div>
                      </div>
                    </b-card> -->
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal dialog -->
    <CustomerModal
      ref="customerModal"
      @openProfileCustomerModal="openProfileCustomerModal"
    ></CustomerModal>
    <SearchCustomerModal
      ref="searchCustomerModal"
      @openProfileCustomerModal="openProfileCustomerModal"
      @openCustomerModal="openCustomerModal"
    ></SearchCustomerModal>
    <CalendarNoteModal
      ref="calendarNoteModal"
      @goToDateTime="goToDateTime"
      @blinkAptId="assignBlinkAptId"
      @openCustomerModal="openCustomerModal(null, null, null, 'calendarNote')"
    />

    <ProfileCustomerModal
      ref="profileCustomerModal"
      @openCustomerModal="openCustomerModal"
      @openEventModal="openEventModal"
      @blinkAptId="assignBlinkAptId"
      @goToDateTime="goToDateTime"
    ></ProfileCustomerModal>
    <PaymentHistoryModal
      ref="paymentHistoryModal"
      @isLoading="isLoading = $event"
    ></PaymentHistoryModal>
    <EventModal
      ref="eventModal"
      @createPatient="createPtFromUnnamedApt"
      @selectPatient="selectPtFromUnnamedApt"
      @goToDateTime="goToDateTime"
      @blinkAptId="assignBlinkAptId"
    ></EventModal>
    <CheckinModal
      ref="checkinModal"
      @openCustomerModal="openCustomerModal"
    ></CheckinModal>
    <AllCheckinModal ref="allCheckinModal"></AllCheckinModal>
    <PaymentModal ref="paymentModal"></PaymentModal>
    <CancelPaymentModal
      ref="cancelPaymentModal"
      @blinkAptId="assignBlinkAptId"
    ></CancelPaymentModal>
    <!-- <PaymentReceiptModal ref="paymentReceiptModal"></PaymentReceiptModal> -->
    <!-- <EmrModal ref="emrModal"></EmrModal> -->
    <StatusModal
      ref="statusModal"
      @hidePopover="hidePopOver()"
      @isLoading="isLoading = $event"
    />
    <ReminderModal
      ref="reminderModal"
      @hidePopover="hidePopOver()"
      @isLoading="isLoading = $event"
    />
    <VerifyModal ref="verifyModalQueueManage" />

    <!-- <SortingDoctorsModal
      ref="sortingDoctorsModal"
      @saveDoctorsList="doctorsList = $event"
    /> -->

    <ColorSidebar ref="colorSidebar" />
    <Loading v-if="isLoading"></Loading>
    <Dialog ref="Dialog"></Dialog>
    <Notification></Notification>
    <PaymentReceiptPreviewModal
      ref="paymentReceiptPreviewInQueueManage"
    ></PaymentReceiptPreviewModal>

    <!-- select resource on calendar -->
    <div id="resourcePopover" class="d-none">
      <b-button-group vertical class="d-inline-flex">
        <b-button id="btnCreatePatient" variant="primary" size="lg">
          <i class="fa fa-user-plus" aria-hidden="true"></i>
        </b-button>
        <b-button id="btnSearchPatient" variant="info" size="lg">
          <i class="fa fa-search" aria-hidden="true"></i>
        </b-button>
        <b-button id="btnCreateUnnamedApt" variant="dark" size="lg">
          <i class="fas fa-user-slash" size="lg"></i>
        </b-button>
        <b-button id="btnCalendarNote" variant="secondary" size="lg">
          <i class="fas fa-sticky-note"></i>
        </b-button>
      </b-button-group>
      <RecentPatients ref="recentPatients" class="d-inline-flex align-top" />
    </div>

    <!-- select event(appointment) on calendar -->
    <div id="appointmentPopover" class="d-none">
      <div v-if="lastFcAppointmentSelected">
        <div v-if="isPatientOne" class="row">
          <div class="col-7 pl-3 pr-0 d-flex">
            <div class="align-self-stretch small-text">
              <p class="mb-1 mt-1">
                {{ lastFcAppointmentSelected.extendedProps.treatmentPeriod }}
                <i class="fas fa-info" id="appointment-info"></i>
              </p>
              <hr class="my-1" />

              <h6 class="narrow-spacing mb-0">
                <strong>
                  คนไข้ใหม่
                  <br />
                  ไม่มีประวัติ
                </strong>
              </h6>
              <hr class="my-1" />
              <p class="mb-1 narrow-spacing">
                แพทย์:
                {{ lastFcAppointmentSelected.extendedProps.doctorName }}
              </p>
              <p class="mb-1">
                ห้อง:
                {{
                  getRoomListBy(
                    lastFcAppointmentSelected.extendedProps.roomId,
                    "id"
                  ).name
                }}
              </p>
              <p class="mb-1 narrow-spacing">
                <strong
                  v-html="
                    lastFcAppointmentSelected.extendedProps.treatmentTitle
                  "
                ></strong>
              </p>
              <p
                class="mb-1 narrow-spacing"
                v-if="lastFcAppointmentSelected.extendedProps.note"
              >
                โน๊ต: {{ lastFcAppointmentSelected.extendedProps.note }}
              </p>
              <b-button
                v-if="lastFcAppointmentSelected.extendedProps.rmdId"
                id="btnReminder"
                ref="btnReminder"
                class="border-0 mb-1"
                block
                :style="{
                  'background-color': findReminderObj(
                    lastFcAppointmentSelected.extendedProps.rmdId
                  ).desc,
                }"
                size="sm"
              >
                {{
                  findReminderObj(lastFcAppointmentSelected.extendedProps.rmdId)
                    .label
                }}
              </b-button>
              <b-button
                id="btnStatus"
                ref="btnStatus"
                class="border-0"
                block
                :disabled="
                  [5, 6, 7, 8].includes(
                    lastFcAppointmentSelected.extendedProps.statusId
                  )
                "
                :style="{
                  'background-color':
                    lastFcAppointmentSelected.extendedProps.statusColor,
                }"
                size="sm"
              >
                {{
                  findStatusName(
                    lastFcAppointmentSelected.extendedProps.statusId
                  ).label
                }}
              </b-button>
            </div>
          </div>
          <div class="col-5 d-flex justify-content-end">
            <b-button-group
              vertical
              class="align-self-stretch"
              v-if="isAccessMenu(['COUNTER', 'MANAGER', 'HEADCOUNTER'])"
            >
              <b-button id="btnCreatePtUnnamedApt" variant="primary">
                <i class="fa fa-id-card"></i> <br />คนไข้ใหม่
              </b-button>
              <b-button id="btnSelectPtUnnamedApt" variant="info">
                <i class="fa fa-search" aria-hidden="true"></i>
                <br />คนไข้เก่า
              </b-button>
              <b-button id="btnEditAppointment" variant="danger">
                <i class="fa fa-pencil-square" aria-hidden="true"></i>
                <br />แก้ไขนัด
              </b-button>
            </b-button-group>
          </div>
        </div>
        <div v-else-if="isPatientNull" class="row">
          <div class="col-7 pl-3 pr-0 d-flex">
            <div class="align-self-stretch small-text">
              <p class="mb-1 mt-1">
                {{ lastFcAppointmentSelected.extendedProps.treatmentPeriod }}
                <i class="fas fa-info" id="appointment-info"></i>
              </p>
              <p
                class="mb-1 narrow-spacing"
                v-if="lastFcAppointmentSelected.extendedProps.note"
              >
                <strong>{{
                  lastFcAppointmentSelected.extendedProps.note
                }}</strong>
              </p>
              <p
                class="mb-1 narrow-spacing"
                v-if="lastFcAppointmentSelected.extendedProps.doctor"
              >
                แพทย์: {{ lastFcAppointmentSelected.extendedProps.doctorName }}
              </p>
            </div>
          </div>
          <div class="col-5 d-flex justify-content-end">
            <b-button id="btnEditCalendarNote" class="btn btn-warning">
              <i class="fas fa-pen"></i>
              <br />แก้ไข
            </b-button>
          </div>
        </div>
        <div
          v-else-if="lastFcAppointmentSelected.extendedProps.linkedWithMFD"
          class="row"
        >
          <div class="col-7 pl-3 pr-0 d-flex">
            <div class="align-self-stretch small-text">
              <p class="mb-1 mt-1">
                {{ lastFcAppointmentSelected.extendedProps.treatmentPeriod }}
                <i class="fas fa-info" id="appointment-info"></i>
              </p>
              <hr class="my-1" />
              <p class="mb-0">
                DN: {{ lastFcAppointmentSelected.extendedProps.dn }}
              </p>
              <p
                v-if="lastFcAppointmentSelected.extendedProps.existDn"
                class="mb-1 description text-muted"
              >
                DN(เดิม):
                {{ lastFcAppointmentSelected.extendedProps.existDn }}
              </p>
              <p
                v-if="
                  lastFcAppointmentSelected.extendedProps.patient.titleNameTh
                "
                class="mb-0"
              >
                {{
                  lastFcAppointmentSelected.extendedProps.patient.titleNameTh
                }}
              </p>
              <h6 class="narrow-spacing mb-0">
                <strong>
                  {{
                    lastFcAppointmentSelected.extendedProps.patient.firstNameTh
                  }}
                  <br />
                  {{
                    lastFcAppointmentSelected.extendedProps.patient.lastNameTh
                  }}
                </strong>
              </h6>
              <p
                class="norrow-spacing mb-0"
                v-if="lastFcAppointmentSelected.extendedProps.patient.dob"
              >
                อายุ:
                {{
                  calAge(lastFcAppointmentSelected.extendedProps.patient.dob)
                }}
                ปี
              </p>
              <hr class="my-1" />
              <p class="mb-1 narrow-spacing">
                โทร: {{ lastFcAppointmentSelected.extendedProps.phoneNo }}
              </p>
              <hr class="my-1" />
              <p class="mb-1 narrow-spacing">
                แพทย์:
                {{ lastFcAppointmentSelected.extendedProps.doctorName }}
              </p>
              <p class="mb-1">
                ห้อง:
                {{
                  getRoomListBy(
                    lastFcAppointmentSelected.extendedProps.roomId,
                    "id"
                  ).name
                }}
              </p>
              <p class="mb-1 narrow-spacing">
                <strong
                  v-html="
                    lastFcAppointmentSelected.extendedProps.treatmentTitle
                  "
                ></strong>
              </p>
              <p
                class="mb-1 narrow-spacing"
                v-if="lastFcAppointmentSelected.extendedProps.note"
              >
                โน๊ต: {{ lastFcAppointmentSelected.extendedProps.note }}
              </p>
              <b-button
                v-if="lastFcAppointmentSelected.extendedProps.rmdId"
                id="btnReminder"
                ref="btnReminder"
                class="border-0 mb-1"
                block
                :style="{
                  'background-color': findReminderObj(
                    lastFcAppointmentSelected.extendedProps.rmdId
                  ).desc,
                }"
                size="sm"
              >
                {{
                  findReminderObj(lastFcAppointmentSelected.extendedProps.rmdId)
                    .label
                }}
              </b-button>
              <b-button
                id="btnStatus"
                ref="btnStatus"
                class="border-0"
                block
                :disabled="
                  [5, 6, 7, 8].includes(
                    lastFcAppointmentSelected.extendedProps.statusId
                  )
                "
                :style="{
                  'background-color':
                    lastFcAppointmentSelected.extendedProps.statusColor,
                }"
                size="sm"
              >
                {{
                  findStatusName(
                    lastFcAppointmentSelected.extendedProps.statusId
                  ).label
                }}
              </b-button>
            </div>
          </div>
          <div class="col-5 d-flex justify-content-end">
            <b-button-group
              vertical
              class="align-self-stretch"
              v-if="isAccessMenu(['COUNTER', 'MANAGER', 'HEADCOUNTER'])"
            >
              <b-button id="btnMorFunDee" variant="primary">
                <img style="width: auto; height: 26px" :src="MorFunDeeLogo" />
                <br />
                Mor <br />
                Fun <br />Dee
              </b-button>
              <b-button id="btnCopyLink" variant="secondary">
                <i class="fas fa-copy"></i>
                <br />
                Copy Link
              </b-button>
              <b-button
                id="btnPaymentHistory"
                class="btn btn-success"
                :disabled="btnDisable || !isTodayApt || inPaidStatus()"
              >
                <i class="fas fa-notes-medical"></i>
                <br />บันทึก <br />รักษา
              </b-button>

              <b-button id="btnPatientDetail" class="btn btn-info">
                <i class="fas fa-info"></i>
                <br />ประวัติ
              </b-button>

              <!-- <b-button
                id="btnEditAppointment"
                class="btn btn-danger"
                :disabled="btnDisable || inClinic()"
              > -->
              <b-button
                id="btnEditAppointment"
                class="btn btn-danger"
                :disabled="false"
              >
                <i class="fa fa-pencil-square" aria-hidden="true"></i>
                <br />แก้ไข
              </b-button>
            </b-button-group>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-7 pl-3 pr-0 d-flex">
            <div class="align-self-stretch small-text">
              <p class="mb-1 mt-1">
                {{ lastFcAppointmentSelected.extendedProps.treatmentPeriod }}
                <i class="fas fa-info" id="appointment-info"></i>
              </p>
              <hr class="my-1" />
              <p class="mb-0">
                DN: {{ lastFcAppointmentSelected.extendedProps.dn }}
              </p>
              <p
                v-if="lastFcAppointmentSelected.extendedProps.existDn"
                class="mb-1 description text-muted"
              >
                DN(เดิม):
                {{ lastFcAppointmentSelected.extendedProps.existDn }}
              </p>
              <p
                v-if="
                  lastFcAppointmentSelected.extendedProps.patient.titleNameTh
                "
                class="mb-0"
              >
                {{
                  lastFcAppointmentSelected.extendedProps.patient.titleNameTh
                }}
              </p>
              <h6 class="narrow-spacing mb-0">
                <strong>
                  {{
                    lastFcAppointmentSelected.extendedProps.patient.firstNameTh
                  }}
                  <br />
                  {{
                    lastFcAppointmentSelected.extendedProps.patient.lastNameTh
                  }}
                </strong>
              </h6>
              <p
                class="norrow-spacing mb-0"
                v-if="lastFcAppointmentSelected.extendedProps.patient.dob"
              >
                อายุ:
                {{
                  calAge(lastFcAppointmentSelected.extendedProps.patient.dob)
                }}
                ปี
              </p>
              <hr class="my-1" />
              <p class="mb-1 narrow-spacing">
                โทร: {{ lastFcAppointmentSelected.extendedProps.phoneNo }}
              </p>
              <hr class="my-1" />
              <p class="mb-1 narrow-spacing">
                แพทย์:
                {{ lastFcAppointmentSelected.extendedProps.doctorName }}
              </p>
              <p class="mb-1">
                ห้อง:
                {{
                  getRoomListBy(
                    lastFcAppointmentSelected.extendedProps.roomId,
                    "id"
                  ).name
                }}
              </p>
              <p class="mb-1 narrow-spacing">
                <strong
                  v-html="
                    lastFcAppointmentSelected.extendedProps.treatmentTitle
                  "
                ></strong>
              </p>
              <p
                class="mb-1 narrow-spacing"
                v-if="lastFcAppointmentSelected.extendedProps.note"
              >
                โน๊ต: {{ lastFcAppointmentSelected.extendedProps.note }}
              </p>
              <b-button
                v-if="lastFcAppointmentSelected.extendedProps.rmdId"
                id="btnReminder"
                ref="btnReminder"
                class="border-0 mb-1"
                block
                :style="{
                  'background-color': findReminderObj(
                    lastFcAppointmentSelected.extendedProps.rmdId
                  ).desc,
                }"
                size="sm"
              >
                {{
                  findReminderObj(lastFcAppointmentSelected.extendedProps.rmdId)
                    .label
                }}
              </b-button>
              <b-button
                id="btnStatus"
                ref="btnStatus"
                class="border-0"
                block
                :disabled="
                  [5, 6, 7, 8].includes(
                    lastFcAppointmentSelected.extendedProps.statusId
                  )
                "
                :style="{
                  'background-color':
                    lastFcAppointmentSelected.extendedProps.statusColor,
                }"
                size="sm"
              >
                {{
                  findStatusName(
                    lastFcAppointmentSelected.extendedProps.statusId
                  ).label
                }}
              </b-button>
            </div>
          </div>
          <div class="col-5 d-flex justify-content-end">
            <b-button-group
              vertical
              class="align-self-stretch"
              v-if="isAccessMenu(['COUNTER', 'MANAGER', 'HEADCOUNTER'])"
            >
              <b-button
                id="btnCheckIn"
                class="btn btn-primary"
                v-show="!inPaymentQueueStatus()"
                :disabled="
                  (!btnDisable && !isTodayApt) ||
                  inPaymentQueueStatus() ||
                  inPaidStatus()
                "
              >
                <i class="fa fa-user-md" aria-hidden="true"></i>
                <br />ส่งตรวจ
              </b-button>
              <b-button
                v-if="inPaymentQueueStatus()"
                id="btnPayment"
                class="btn btn-success rounded-top"
              >
                <i class="fas fa-cash-register"></i>
                <br />ชำระเงิน
              </b-button>
              <b-button
                id="btnPaymentHistory"
                class="btn btn-success"
                v-else
                :disabled="btnDisable || !isTodayApt || inPaidStatus()"
              >
                <i class="fas fa-notes-medical"></i>
                <br />บันทึก <br />รักษา
              </b-button>
              <button
                v-show="inPaymentQueueStatus()"
                id="ิbtnCancelPaymentQueue"
                class="btn btn-warning px-1"
                :disabled="!isTodayApt"
              >
                <i class="fas fa-redo"></i>
                <span class="narrow-spacing">
                  <br />ยกเลิกชำระ <br />/ ส่งต่อ
                </span>
              </button>

              <b-button id="btnPatientDetail" class="btn btn-info">
                <i class="fas fa-info"></i>
                <br />ประวัติ
              </b-button>
              <b-button
                id="btnCancelPaotang"
                class="btn btn-danger"
                v-if="lastFcAppointmentSelected.extendedProps.statusId === 11"
              >
                <i class="fa fa-pencil-square" aria-hidden="true"></i>
                <br />ยกเลิกชำระด้วยเป๋าตัง
              </b-button>
              <b-button
                id="btnIssueReceipt"
                class="btn btn-success"
                v-else-if="
                  lastFcAppointmentSelected.extendedProps.statusId === 12
                "
              >
                <i class="fa fa-pencil-square" aria-hidden="true"></i>
                <br />พิมพ์ใบเสร็จ
              </b-button>
              <b-button
                id="btnEditAppointment"
                class="btn btn-danger"
                :disabled="btnDisable || inClinic()"
                v-else
              >
                <i class="fa fa-pencil-square" aria-hidden="true"></i>
                <br />แก้ไข
              </b-button>
            </b-button-group>
            <b-button-group vertical class="align-self-stretch" v-else>
              <b-button id="btnPatientDetail" class="btn btn-info">
                <i class="fas fa-info"></i>
                <br />ประวัติ
              </b-button>
            </b-button-group>
          </div>
        </div>
      </div>
    </div>

    <div v-if="extendedProps" id="appointment-detail" class="d-none">
      <div class="small-text">
        <div class="small">
          สร้างโดย:
          <div class="text-muted">
            {{ getUserListBy(extendedProps.creationUid, "uid").fullName }}
            <br />
            {{ formatDateTime(extendedProps.creationDt) }}<br />
          </div>
        </div>
        <div v-if="isAptModified" class="small">
          แก้ไขโดย:
          <div clss="text-muted">
            {{ getUserListBy(extendedProps.modifyUid, "uid").fullName }}<br />
            {{ formatDateTime(extendedProps.modifyDt) }}
          </div>
        </div>
      </div>
    </div>
    <UploadFilesModal ref="uploadFiles" />
  </div>
</template>


<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "@/main";

import CustomerModal from "@/components/modal/Customer";
import SearchCustomerModal from "@/components/modal/SearchCustomer";
import CalendarNoteModal from "@/components/modal/CalendarNote";
import ProfileCustomerModal from "@/components/modal/ProfileCustomer";
import PaymentHistoryModal from "@/components/modal/PaymentHistory";
import EventModal from "@/components/modal/Event";

import CheckinModal from "@/components/modal/Checkin";
import AllCheckinModal from "@/components/modal/AllCheckin";
import PaymentModal from "@/components/modal/Payment";
import CancelPaymentModal from "@/components/modal/CancelPayment";
import VerifyModal from "@/components/modal/VerifyCancelReceipt";

import PaymentReceiptPreviewModal from "@/components/modal/PaymentReceiptPreviewModal";

import StatusModal from "@/components/modal/Status";
import ReminderModal from "@/components/modal/Reminder";
import ColorSidebar from "@/components/menubar/ColorSidebar";

import AdsCard from "@/components/Ads";
import RecentPatients from "@/components/RecentPatients";

import Dialog from "@/components/modal/Dialog";
import Notification from "@/components/Notification";
import Loading from "@/components/Loading";

import moment from "moment";
import fullCalendar from "@fullcalendar/vue";
import interaction from "@fullcalendar/interaction";
import fcBootstrap from "@fullcalendar/bootstrap";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import locales from "@fullcalendar/core/locales/th";
import bootstrap from "bootstrap"; //ห้ามลบ jquery ใช้
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import $ from "jquery";
import "@/theme/QueueManage.scss";

import UploadFilesModal from "@/components/modal/UploadFiles";
import MorFunDeeLogo from "../assets/img/morfundee-logo.png";
import NHSOLogo from "../assets/img/NHSO_logo.png";

import { datetime, RRule, RRuleSet, rrulestr } from "rrule";

export default {
  name: "QueueManage",
  components: {
    CustomerModal,
    SearchCustomerModal,
    CalendarNoteModal,
    ProfileCustomerModal,
    PaymentHistoryModal,
    EventModal,
    CheckinModal,
    AllCheckinModal,
    fullCalendar,
    Dialog,
    Notification,
    Loading,
    PaymentModal,
    CancelPaymentModal,
    VerifyModal,
    PaymentReceiptPreviewModal,
    StatusModal,
    ReminderModal,
    ColorSidebar,
    AdsCard,
    RecentPatients,
    UploadFilesModal,
  },
  data() {
    return {
      isLoading: false,
      masterDataCouter: 0,
      alertConfirmMoveEvent: false,
      appointmentTempData: null,
      themeSystem: "bootstrap",
      displayPage: false,
      dateDefault: moment().format("YYYY-MM-DD"),
      dateShower: false,
      schedulerLicenseKey: "0650622495-fcs-1679632464",
      locales: locales,
      slotLabelFormat: {
        hour: "2-digit",
        minute: "2-digit",
        omitZeroMinute: false,
        meridiem: "short",
      },
      header: {
        left: "",
        center: "title",
        right: "prevDay,todayButton,nextDay, checkInList",
      },
      headerDoctors: {
        left: "",
        center: "title",
        right: "prevDay,todayButton,nextDay, checkInList",
      },
      titleFormat: {
        month: "long",
        year: "2-digit",
        day: "numeric",
        weekday: "short",
      },
      calendarPlugins: [
        interaction,
        resourceTimeGridPlugin,
        fcBootstrap,
        momentTimezonePlugin,
      ],
      windowHeight: window.innerHeight,
      fetchEventTimeout: 0,
      lastFcAppointmentSelected: null,
      lastFcResourceSelected: null,
      popEl: null,
      btnDisable: false,
      isTodayApt: true,
      isCalendarOpen: true,
      paymentQueueTableHeader: [
        {
          key: "index",
          label: "",
          class: "text-center",
        },
        {
          key: "fullname",
          label: "คิวรอชำระเงิน",
        },
        {
          key: "doctor",
          label: "แพทย์",
          sortable: true,
        },
        {
          key: "treatmentTime",
          label: "ตรวจเสร็จ",
          sortable: true,
        },
      ],
      paotangTableHeader: [
        {
          key: "index",
          label: "",
          class: "text-center",
        },
        {
          key: "fullname",
          label: "รอชำระด้วยเป๋าตัง",
        },
        {
          key: "doctor",
          label: "แพทย์",
          sortable: true,
        },
        {
          key: "treatmentTime",
          label: "สถานะ",
          sortable: true,
        },
      ],
      tabIndex: 0,
      blinkAptId: null,
      lastAppointmentId: null,
      MorFunDeeLogo,
      NHSOLogo,
    };
  },
  created() {
    this.init();
  },
  watch: {
    dateDefault: function (date) {
      this.getAppointmentListByDate(date);
      this.setStateQueueDate(date);
      this.fcGotoDate(date);
    },
    lastFcAppointmentSelected(val) {
      this.isTodayApt = moment().isSame(
        this.lastFcAppointmentSelected.start,
        "day"
      );
    },
  },
  computed: {
    ...mapGetters({
      roomList: "moduleUser/getRoomList",
      getRoomListBy: "moduleUser/getRoomListBy",
      branchInfo: "moduleUser/getBranchInfo",
      branchLinkedWithKtbPaotang: "moduleUser/getLinkedWithKtbPaotang",
      appointmentList: "moduleAppointment/getQueueAppointmentList",
      appointmentBy: "moduleAppointment/getQueueAppointmentBy",
      waitingForPaymentApptList:
        "moduleAppointment/getWaitingForPaymentApptList",
      poatangApptList: "moduleAppointment/getPoatangApptList",
      getAppointmentStatusList: "moduleMaster/getAppointmentStatusList",
      getReminderList: "moduleMaster/getReminderList",
      getDoctorList: "moduleUser/getDoctorList",
      getUserListBy: "moduleUser/getUserListBy",
      getRecentPatients: "modulePatient/getRecentPatients",
      isAccessMenu: "moduleUser/getIsAccessMenu",
      getDoctorHour: "moduleUser/getDoctorHour",
      branchLinkedWithNhso: "moduleUser/getLinkedWithNhso",
    }),
    slotDuration() {
      return this.branchInfo?.confApptDuration
        ? this.branchInfo.confApptDuration
        : "00:15";
    },
    openingTime() {
      return this.branchInfo?.confOpeningTime
        ? this.branchInfo.confOpeningTime
        : "09:00";
    },
    closingTime() {
      return this.branchInfo?.confClosingTime
        ? this.branchInfo.confClosingTime
        : "21:00";
    },
    fullCalendarHeight() {
      return this.windowHeight - 80;
    },
    customButtons() {
      return {
        prevDay: {
          text: "prev",
          bootstrapFontAwesome: "fa-chevron-left",
          click: () => {
            this.dateDefault = moment(this.dateDefault)
              .add(-1, "days")
              .format("YYYY-MM-DD");
          },
        },
        todayButton: {
          text: "วันนี้",
          click: () => {
            this.dateDefault = moment().format("YYYY-MM-DD");
          },
        },
        nextDay: {
          text: "next",
          bootstrapFontAwesome: "fa-chevron-right",
          click: () => {
            this.dateDefault = moment(this.dateDefault)
              .add(1, "days")
              .format("YYYY-MM-DD");
          },
        },
        collapseCalendar: {
          text: "Collapse",
          bootstrapFontAwesome: "angle-double-up",
          click: () => {
            this.isCalendarOpen = !this.isCalendarOpen;
          },
        },
        toggleSidebar: {
          text: "toggle",
          bootstrapFontAwesome: "palette",
          click: () => {
            this.$refs.colorSidebar.toggle();
          },
        },
        checkInList: {
          text: "Check-In List",
          bootstrapFontAwesome: "clipboard-list",
          click: () => {
            this.$refs.allCheckinModal.show(this.doctorsList);
          },
        },
        // openSortingDoctorsModal: {
        //   text: "Sorting",
        //   bootstrapFontAwesome: "columns",
        //   click: () => {
        //     this.openSortingDoctorsModal();
        //   },
        // },
      };
    },
    scrollTime() {
      return moment().format("HH:mm:ss");
    },
    tablePaymentList() {
      return this.waitingForPaymentApptList.map(item => {
        return {
          id: item.id,
          fullname:
            (item.extendedProps.patient.titleNameTh || "") +
            " " +
            item.extendedProps.patient.firstNameTh +
            " " +
            item.extendedProps.patient.lastNameTh,
          treatmentTime: item.extendedProps.treatmentDt,
          doctor: item.extendedProps.doctorName,
          roomId: item.extendedProps.roomId,
          roomName: this.getRoomListBy(item.extendedProps.roomId, "id").name,

          _rowVariant: "",
          _aptData: item,
          blink: false,
        };
      });
    },
    paotangList() {
      return this.poatangApptList.map(item => {
        return {
          id: item.id,
          fullname:
            (item.extendedProps.patient.titleNameTh || "") +
            " " +
            item.extendedProps.patient.firstNameTh +
            " " +
            item.extendedProps.patient.lastNameTh,
          treatmentTime: item.extendedProps.treatmentDt,
          doctor: item.extendedProps.doctorName,
          roomId: item.extendedProps.roomId,
          roomName: this.getRoomListBy(item.extendedProps.roomId, "id").name,

          statusId: item.extendedProps.statusId,
          patientId: item.extendedProps.patient.id,
          _rowVariant: item.extendedProps.statusId === 11 ? "info" : "success",
          _aptData: item,
          blink: false,
        };
      });
    },
    appointmentListByDoctors() {
      const doctorIds = this.doctorsList.map(i => i.id);
      return this.appointmentList.map(apt => {
        return {
          ...apt,
          resourceId: doctorIds.includes(apt.extendedProps.doctor)
            ? apt.extendedProps.doctor
            : null,
        };
      });
    },
    extendedProps() {
      return this.lastFcAppointmentSelected?.extendedProps;
    },
    isAptModified() {
      if (!this.extendedProps.modifyDt) return false;
      if (this.extendedProps.creationDt === this.extendedProps.modifyDt)
        return false;
      return true;
    },
    isPatientOne() {
      return this.lastFcAppointmentSelected?.extendedProps?.patient?.id == 1;
    },
    isPatientNull() {
      return !this.lastFcAppointmentSelected?.extendedProps?.patient?.id;
    },
    doctorsList() {
      let startTime, endTime;
      let list = this.getDoctorList
        .map(({ uid, fullName, drApptColor, doctorWorkingHours }) => {
          if (
            doctorWorkingHours?.some(i => {
              startTime = i.startTime;
              endTime = i.endTime;
              return this.checkRruleIfSameDay(i.rruleStr);
            })
          ) {
            return {
              id: uid,
              title: fullName,
              businessHours: {
                daysOfWeek: [0, 1, 2, 3, 4, 5, 6], // include 0,6 for SUN, SAT
                startTime,
                endTime,
              },
            };
          } else {
            return;
          }
        })
        .filter(i => i);
      // if (list.length === 0) list = [{ id: null, title: "ไม่มีแพทย์ลงตรวจ" }];
      list.push({
        id: null,
        title: "นัดหมายอื่นๆ",
      });
      return list;
    },
  },
  methods: {
    ...mapActions({
      fetchQueueAppointmentList: "moduleAppointment/fetchQueueAppointmentList",
      fetchWaitingForPaymentApptList:
        "moduleAppointment/fetchWaitingForPaymentApptList",

      moveQueueAppointment: "moduleAppointment/moveQueueAppointment",
      setStateQueueDate: "moduleAppointment/setStateQueueDate",
      cancelWaitingForPaymentAppointment:
        "moduleAppointment/cancelWaitingForPaymentAppointment",
      waitingForPaymentAppointment:
        "moduleAppointment/waitingForPaymentAppointment",
      addRecentPatient: "modulePatient/addRecentPatient",

      checkCreateReceipt: "moduleReceipt/checkCreateReceipt",
      fetchPatientById: "modulePatient/fetchPatientById",
    }),
    init() {
      this.isLoading = true;
      this.getAppointmentListByDate(this.dateDefault);
      this.setStateQueueDate(this.dateDefault);
    },
    offJQuery() {
      $(document).off("click", "#btnMorFunDee");
      $(document).off("click", "#btnCopyLink");
      $(document).off("click", "#btnPaymentHistory");
      $(document).off("click", "#btnPatientDetail");
      $(document).off("click", "#btnCreatePatient");
      $(document).off("click", "#btnSearchPatient");
      $(document).off("mouseenter", "#btnSearchPatient");

      $(document).off("click", "#btnCalendarNote");
      $(document).off("click", "#btnEditAppointment");
      $(document).off("click", "#btnCheckIn");
      $(document).off("click", "#btnPayment");
      $(document).off("click", "#ิbtnCancelPaymentQueue");
      $(document).off("click", "#btnEditCalendarNote");
      $(document).off("click", "#btnCancelPaotang");
      $(document).off("click", "#btnIssueReceipt");
      $(document).off("mouseenter", "#appointment-info");
      $(document).off("mouseleave", "#appointment-info");

      $(document).off("click", "#btnStatus");
      $(document).off("click", "#btnReminder");
      $(document).off("click.hidePopover");
    },
    onJQuery() {
      const self = this;
      $(document).on("click", "#btnPaymentHistory", async () => {
        const appointment = this.appointmentBy(
          this.lastFcAppointmentSelected.id,
          "id"
        );
        if (this.lastFcAppointmentSelected.extendedProps.statusId === 5) {
          self.openPaymentHistoryModal(appointment);
          self.hidePopOver();

          // click ที่ dialog แล้วยังไม่ปิด popover
          document
            .querySelector(".swal2-container")
            ?.addEventListener("click", e => e.stopPropagation());
        } else {
          if (this.branchLinkedWithNhso) {
            await this.$refs.Dialog.showAlertConfirm(
              "โครงการ30 บาทรักษาทุกที่?",
              "บันทึกการรักษาคนไข้โครงการฯ",
              null,
              "โครงการ30 บาทรักษาทุกที่",
              "คนไข้ชำระเอง"
            )
              .then(async res => {
                const { isDismissed, dismiss, isConfirmed } = res;
                if (isDismissed && dismiss === "backdrop") return;
                const linkedWithNhso = isConfirmed;
                const p = /^\d{13}$/.test(
                  appointment?.extendedProps?.patient?.personalId
                );

                if (!p && linkedWithNhso) {
                  this.$refs.Dialog.showAlertInfo(
                    "ไม่สามารถ Check-in ได้เนื่องจากบัตรประชาชนไม่ถูกต้อง"
                  );
                } else {
                  this.isLoading = true;
                  await this.$store.dispatch(
                    "moduleAppointment/confirmQueueCheckIn",
                    {
                      event: {
                        linkedWithNhso,
                        roomId: appointment.extendedProps.roomId,
                        doctorUid: appointment.extendedProps.doctor,
                        aptTreatItems: appointment.extendedProps.treatmentList,
                        nhsoSubitemNo: linkedWithNhso ? "D10" : null,
                      },
                      id: appointment.id,
                      clinicUrl: this.$route.params.clinicUrl,
                      branchUrl: this.$route.params.branchUrl,
                    }
                  );
                  self.openPaymentHistoryModal(appointment);
                  self.hidePopOver();
                }
              })
              .catch(err => {
                console.error(err);
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            self.openPaymentHistoryModal(appointment);
            self.hidePopOver();
          }
        }
      });
      $(document).on("click", "#btnPatientDetail", () => {
        const patient = this.lastFcAppointmentSelected.extendedProps.patient;
        const appointment = this.appointmentBy(
          this.lastFcAppointmentSelected.id,
          "id"
        );
        self.openProfileCustomerModal(
          appointment,
          patient,
          false,
          "createAptOnly"
        );
        self.hidePopOver();
      });
      $(document).on("click", "#btnCreatePatient", async () => {
        await this.$nextTick();
        self.openCustomerModal();
        self.hidePopOver();
      });
      $(document).on("click", "#btnSearchPatient", async () => {
        await this.$nextTick();
        self.openSearchCustomerModal();
        self.hidePopOver();
      });
      $(document).on("click", "#btnCreateUnnamedApt", async () => {
        await this.$nextTick();
        self.openEventModal(this.lastFcResourceSelected, { id: 1 });
        self.hidePopOver();
        await this.$nextTick();
        this.fcResourceSelected = null;
      });
      $(document).on("click", "#btnCalendarNote", () => {
        self.openCalendarNoteModal();
        self.hidePopOver();
      });
      $(document).on("click", "#btnEditCalendarNote", () => {
        const appointment = this.appointmentBy(
          this.lastFcAppointmentSelected.id,
          "id"
        );
        self.$refs.calendarNoteModal.show(appointment);
        self.hidePopOver();
      });
      $(document).on("click", "#btnEditAppointment", () => {
        const appointment = this.appointmentBy(
          this.lastFcAppointmentSelected.id,
          "id"
        );
        self.$refs.eventModal.show(appointment);
        self.hidePopOver();
      });
      $(document).on("click", "#btnCheckIn", () => {
        var indfEvent = self.appointmentList.findIndex(
          i => i.id === parseInt(self.lastFcAppointmentSelected.id)
        );
        let statusId = self.lastFcAppointmentSelected.extendedProps.statusId;
        if (statusId != 5) {
          self.$refs.checkinModal.show(indfEvent);
          self.hidePopOver();
        }
        if (statusId === 5) {
          self.$refs.Dialog.showAlertConfirm(
            "ยกเลิกส่งตรวจ ?",
            "คุณต้องการยกเลิกส่งตรวจ",
            "warning",
            "ใช่",
            "ไม่"
          ).then(res => {
            if (res.isConfirmed) {
              this.isLoading = true;
              self.$refs.checkinModal
                .cancelCheckin(indfEvent)
                .then(res => {
                  this.showDialogToast("success", "ยกเลิกส่งตรวจสำเร็จ");
                })
                .catch(err => {
                  if (err.response.data.errorMessage === "APPT-HAS-TREAT") {
                    this.showDialogToast(
                      "warning",
                      "ไม่สามารถยกเลิกส่งตรวจได้ เนื่องจากมีการบันทึกรายการรักษาแล้ว"
                    );
                    return;
                  }
                  this.showDialogToast("warning", "ยกเลิกส่งตรวจไม่สำเร็จ");
                })
                .finally(() => {
                  this.isLoading = false;
                });
              self.hidePopOver();
            }
          });
        }
      });
      $(document).on("click", "#btnPayment", () => {
        self.openPaymentModal(this.lastFcAppointmentSelected);
        self.hidePopOver();
      });
      $(document).on("click", "#ิbtnCancelPaymentQueue", () => {
        self.$refs.cancelPaymentModal.show(this.lastFcAppointmentSelected);
        self.hidePopOver();
      });
      $(document).on("click", "#btnMorFunDee", () => {
        window
          .open(
            this.lastFcAppointmentSelected.extendedProps
              .mfdAppointmentMeetingUrl + "&mode=dentist",
            "_blank"
          )
          .focus();
        self.hidePopOver();
      });
      $(document).on("click", "#btnCopyLink", async () => {
        try {
          await navigator.clipboard.writeText(
            `${this.lastFcAppointmentSelected.extendedProps.mfdClipboradText}`
          );
          eventBus.$emit("alertToast", {
            message: "บันทึกใส่คลิปบอร์ดสำเร็จ",
            variant: "success",
          });
        } catch (error) {
          console.error(error);
          eventBus.$emit("alertToast", {
            message: "บันทึกใส่คลิปบอร์ดไม่สำเร็จ",
            variant: "warning",
          });
        }
      });

      //dismiss popover when click outside
      $(document).on("click.hidePopover", e => {
        if (
          self.popEl &&
          !$(self.popEl).is(e.target) &&
          $(self.popEl).has(e.target).length === 0 &&
          $(".popover").has(e.target).length === 0
        ) {
          self.hidePopOver();
        }
      });
      $(document).on("click", "#btnStatus", () => {
        self.openStatusModal(this.lastFcAppointmentSelected);
      });
      $(document).on("click", "#btnReminder", () => {
        self.openReminderModal(this.lastFcAppointmentSelected);
      });

      $(document).on("click", "#btnCreatePtUnnamedApt", async () => {
        await this.$nextTick();
        self.createPtFromUnnamedApt({
          openFrom: "QManage",
        });
        self.hidePopOver();
      });
      $(document).on("click", "#btnCancelPaotang", async () => {
        await this.$nextTick();
        self.openDialogCancelPaotangPayment(this.lastFcAppointmentSelected);
        self.hidePopOver();
      });
      $(document).on("click", "#btnIssueReceipt", async () => {
        await this.$nextTick();
        self.openPaotangPrintingReceipt(this.lastFcAppointmentSelected);
        self.hidePopOver();
      });
      $(document).on("click", "#btnSelectPtUnnamedApt", async () => {
        await this.$nextTick();
        self.selectPtFromUnnamedApt({
          openFrom: "QManage",
        });
        self.hidePopOver();
      });
      $(document).on("mouseenter", "#appointment-info", async e => {
        Vue.nextTick().then(res => {
          let el = $(e.target)
            .popover({
              content: () => $("#appointment-detail").html(),
              placement: "right",
              boundary: "scrollParent",
              boundaryPadding: 2,
              delay: 500,
              offset: 0,
              triggers: "",
              html: true,
              container: "#calendarCol",
              sanitize: false,
            })
            .popover("show");
        });
        $(document).on("mouseleave", "#appointment-info", async e => {
          $(e.target).popover("hide");
        });
      });
    },
    getAppointmentListByDate(date, silent = false) {
      if (!silent) this.isLoading = true;
      if (!date) date = this.dateDefault;
      this.fetchQueueAppointmentList({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        date: date,
      })
        .then(() => {
          this.isLoading = false;
        })
        .catch(err => {
          console.error(err);
          this.isLoading = false;
        })
        .finally(() => {
          this.fcUpdateSize();
        });
    },
    getAppointmentFormat(fcResourceSelected) {
      return {
        start: fcResourceSelected.startStr,
        end: fcResourceSelected.endStr,
        // resourceId: parseInt(fcResourceSelected.resource.id),
        roomId: fcResourceSelected.roomId,
        doctor: fcResourceSelected.doctor,
      };
    },
    async openCustomerModal(patient, appointment = null, readOnly, openFrom) {
      if (!appointment && this.lastFcResourceSelected) {
        appointment = this.getAppointmentFormat(this.lastFcResourceSelected);
      }
      this.$nextTick().then(res => {
        if (this.$refs.customerModal) {
          this.$refs.customerModal.show(
            patient,
            appointment,
            readOnly,
            openFrom
          );
        }
      });
      await this.$nextTick();
      this.lastFcResourceSelected = null;
    },
    createPtFromUnnamedApt({ patient, appointment, readOnly, openFrom }) {
      this.$refs.customerModal.show(
        patient,
        this.lastFcAppointmentSelected,
        readOnly,
        openFrom
      );
    },

    openSearchCustomerModal(searchValue) {
      let appointment;
      if (this.lastFcResourceSelected) {
        appointment = this.getAppointmentFormat(this.lastFcResourceSelected);
      }
      this.$nextTick().then(res => {
        this.$refs.searchCustomerModal.show(appointment, searchValue);
      });
      this.lastFcResourceSelected = null;
    },
    selectPtFromUnnamedApt({ appointment, searchValue, openFrom }) {
      this.$refs.searchCustomerModal.show(
        this.lastFcAppointmentSelected || appointment,
        searchValue,
        openFrom
      );
    },

    async openCalendarNoteModal() {
      let appointment;
      if (this.lastFcResourceSelected) {
        appointment = this.getAppointmentFormat(this.lastFcResourceSelected);
      }
      this.$refs.calendarNoteModal.show(appointment);
      await this.$nextTick();
      this.lastFcResourceSelected = null;
    },
    openProfileCustomerModal(appointment, patient, hideFooter, mode) {
      this.$refs.profileCustomerModal.show(
        appointment ||
          (this.lastFcResourceSelected === null
            ? null
            : this.getAppointmentFormat(this.lastFcResourceSelected)),
        patient,
        hideFooter,
        mode
      );
      this.hidePopOver();
    },
    openPaymentHistoryModal(appointment, patient) {
      this.$refs.paymentHistoryModal.show(appointment, patient);
    },
    async openEventModal(appointment, patient, openFrom) {
      this.$refs.eventModal.show(appointment, patient, openFrom);
    },

    openConfirmationModal() {
      this.$refs.confirmationModal.show();
    },
    openCheckInModal(index, appointment, patient) {
      this.$refs.checkinModal.show(index, appointment, patient);
    },
    cancelCheckIn(index) {
      this.$refs.checkinModal.cancelCheckin(index);
    },
    // openEmrModal(patient, drawObj, readOnly) {
    //   this.$refs.emrModal.show(patient, drawObj, readOnly);
    // },
    openNextVisitModal(patient) {
      this.$refs.nextVisitModal.show(patient);
    },
    openPaymentModal(data, index) {
      if (this.isAccessMenu(["COUNTER", "MANAGER", "HEADCOUNTER"])) {
        this.$refs.paymentModal.show(data._aptData || data);
      }
    },
    openStatusModal(appointment) {
      this.$refs.statusModal.show(appointment);
    },
    openReminderModal(appointment) {
      this.$refs.reminderModal.show(appointment);
    },
    // openSortingDoctorsModal() {
    //   this.$refs.sortingDoctorsModal.show(this.doctorsList);
    // },
    renderCalendarEvents(info) {
      function shadeColor(color, percent) {
        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseInt((R * (100 + percent)) / 100);
        G = parseInt((G * (100 + percent)) / 100);
        B = parseInt((B * (100 + percent)) / 100);

        R = R < 255 ? R : 255;
        G = G < 255 ? G : 255;
        B = B < 255 ? B : 255;

        R = Math.round(R / 10) * 10;
        G = Math.round(G / 10) * 10;
        B = Math.round(B / 10) * 10;

        var RR =
          R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
        var GG =
          G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
        var BB =
          B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

        return "#" + RR + GG + BB;
      }
      const fcTime = info.el.getElementsByClassName("fc-time")[0];
      let eventProps = info.event.extendedProps;

      if (eventProps.patient?.id) {
        fcTime.classList.add("sideStatus");
        if (eventProps.patient.id === 1) {
          info.el.classList.add("fc-unnamed-patient");
          info.el.style.backgroundColor = shadeColor(
            eventProps.doctorColor,
            -5
          );
        } else {
          info.el.style.backgroundColor = eventProps.doctorColor;
        }
        info.el.style.borderColor = eventProps.doctorColorBorder;
        fcTime.style.backgroundColor = eventProps.statusColor;
      } else {
        info.el.classList.add("fc-note-event");
        info.el.style.backgroundColor = "#5a6268";
        fcTime.style.display = "none";
      }

      if (info.event.id == this.blinkAptId) {
        info.el.classList.add("blink");
        setTimeout(() => info.el.classList.remove("blink"), 60000);
      }
      if (eventProps.linkedWithMFD) {
        $(info.el.querySelector(".fc-title")).prepend(
          `<img class="mr-1 rounded" style="width: auto; height: 16px" src="${MorFunDeeLogo}"/ >`
        );
      } else if (eventProps.nhsoVisitBookingNo) {
        $(info.el.querySelector(".fc-title")).prepend(
          `<img class="mr-1 rounded" style="width: auto; height: 16px" src="${NHSOLogo}"/ >`
        );
      } else if (eventProps.rmdId) {
        let rmdObj = this.findReminderObj(eventProps.rmdId);
        $(info.el.querySelector(".fc-title")).prepend(
          `<span class="mr-1 px-1 rounded" style="color: white; background-color: ${rmdObj.desc}">${rmdObj.code}</span>`
        );
      }
    },
    onViewSkeletonRender() {
      $("#calendar .fc-view-container").css({ "overflow-x": "auto" });
      $("#calendar .table-bordered").css(
        "min-width",
        $(".fc-resource-cell").length * 110
      );
    },
    fcWindowResize(arg) {
      this.windowHeight = window.innerHeight;
    },
    fcAppointmentClick(info) {
      this.lastFcAppointmentSelected = Object.assign(info.event);
      this.popEl = info.el;
      this.hidePopOver();
      Vue.nextTick().then(res => {
        $(info.el)
          .popover({
            content: () => $("#appointmentPopover").html(),
            placement: "right",
            boundary: "scrollParent",
            boundaryPadding: 5,
            delay: 500,
            offset: 0,
            triggers: "",
            html: true,
            container: "#calendarRoom",
            sanitize: false,
          })
          .popover("show");
      });
      this.checkStatusCheckin(this.lastFcAppointmentSelected);
      let patient = this.lastFcAppointmentSelected.extendedProps.patient;
      if (patient) this.addRecentPatient(patient);
    },
    fcAppointmentDoctorClick(info) {
      this.lastFcAppointmentSelected = Object.assign(info.event);
      this.popEl = info.el;
      this.hidePopOver();
      Vue.nextTick().then(res => {
        $(info.el)
          .popover({
            content: () => $("#appointmentPopover").html(),
            placement: "right",
            boundary: "scrollParent",
            boundaryPadding: 5,
            delay: 500,
            offset: 0,
            triggers: "",
            html: true,
            container: "#calendarDoctor",
            sanitize: false,
          })
          .popover("show");
      });
      this.checkStatusCheckin(this.lastFcAppointmentSelected);
    },
    fcRoomResourceClick(info) {
      this.fcUpdateSize();
      this.lastFcResourceSelected = { ...info, roomId: info.resource.id };
      this.popEl = info.jsEvent.target;
      this.hidePopOver();

      this.$refs.recentPatients.updateTime();

      Vue.nextTick().then(res => {
        $(info.jsEvent.target)
          .popover({
            content: () => $("#resourcePopover").html(),
            placement: "right",
            boundary: "scrollParent",
            boundaryPadding: 5,
            delay: 500,
            offset: 0,
            triggers: "",
            html: true,
            container: "#calendarRoom",
            sanitize: false,
            template:
              '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body p-1"></div></div>',
          })
          .popover("show");
      });
    },
    fcDoctorResourceClick(info) {
      this.fcUpdateSize();
      this.lastFcResourceSelected = { ...info, doctor: info.resource.id };
      this.popEl = info.jsEvent.target;
      this.hidePopOver();
      Vue.nextTick().then(res => {
        $(info.jsEvent.target)
          .popover({
            content: () => $("#resourcePopover").html(),
            placement: "right",
            boundary: "scrollParent",
            boundaryPadding: 5,
            delay: 500,
            offset: 0,
            triggers: "",
            html: true,
            container: "#calendarDoctor",
            sanitize: false,
            template:
              '<div class="popover" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body p-1"></div></div>',
          })
          .popover("show");
      });
    },
    hidePopOver() {
      $(".popover").not(this).popover("hide");
    },
    updateAppointment(data, resourceType = "room") {
      var index = this.appointmentList.findIndex(
        i => i.id === parseInt(data.event.id)
      );
      const startDt = moment(data.event.start).format();
      let endDt = moment(data.event.end).format();
      if (!data.event.end) {
        endDt = moment(startDt).add(60, "minutes").format();
      }
      const duration = moment.duration();

      let branchId = this.branchInfo.id;
      let eventData = {
        branchId,
        id: this.appointmentList[index].id,
        roomId: this.appointmentList[index].extendedProps.roomId,
        doctorUid: this.appointmentList[index].extendedProps.doctor,
        startDt: startDt,
        endDt: endDt,
      };

      if (data.newResource) {
        if (resourceType === "room") {
          eventData.roomId = parseInt(data.newResource.id);
        } else {
          eventData.doctorUid = parseInt(data.newResource.id);
        }
      }
      this.appointmentTempData = { eventData, eventTarget: data };
      var eventProps = this.appointmentList[index].extendedProps;
      var checkin = eventProps.checkin;

      if (checkin && checkin.checkinDt) {
        this.appointmentTempData.eventTarget.revert();
      } else {
        this.$refs.Dialog.showAlertConfirm(
          "แก้ไขนัดหมาย ?",
          "คุณต้องการแก้ไขนัดหมาย"
        ).then(result => {
          if (this.appointmentTempData) {
            if (result.value) {
              this.isLoading = true;
              let event = this.appointmentTempData.eventData;
              delete event.branchId;
              this.moveQueueAppointment({
                event,
                clinicUrl: this.$route.params.clinicUrl,
                branchUrl: this.$route.params.branchUrl,
              })
                .then(res => {
                  this.isLoading = false;
                })
                .catch(() => {
                  this.isLoading = false;
                });
            } else {
              this.appointmentTempData.eventTarget.revert();
            }
            this.alertConfirmMoveEvent = false;
            this.appointmentTempData = null;
          }
        });
      }
    },
    async checkStatusCheckin(dataCheckin) {
      var index = this.appointmentList.findIndex(
        i => i.id === parseInt(dataCheckin.id)
      );
      var eventProps = this.appointmentList[index].extendedProps;
      var checkin = eventProps.checkin;
      await this.$nextTick();
      if (eventProps.statusId == 5) {
        $("#btnCheckIn").html(
          '<i class="fa fa-user-md" aria-hidden="true"></i><br />ยกเลิก<br />ส่งตรวจ'
        );
        // $("#btnCheckIn").prop("cancel-checkin", true);
        this.btnDisable = false;
      } else {
        $("#btnCheckIn").html(
          '<i class="fa fa-user-md" aria-hidden="true"></i><br />ส่งตรวจ'
        );
        // $("#btnCheckIn").prop("cancel-checkin", false);
        this.btnDisable = false;
      }
    },
    goToDateTime(dateTime) {
      let date = moment(dateTime).format("YYYY-MM-DD");
      let time = moment(dateTime).format("HH:mm:ss");

      //แก้ถ้าสร้างใหม่ในที่ display จะไม่ blink
      if (this.dateDefault === date) {
        this.getAppointmentListByDate(date);
        this.setStateQueueDate(date);
        this.fcGotoDate(date);
      } else {
        this.dateDefault = date;
      }
      this.$nextTick(() => {
        this.fcScrolltoTime(time);
      });
      // window.scrollTo(0, 0); //scroll เพื่อให้เห็นวันที่
    },
    showDialogToast(variant, textDetail, noAutoHide) {
      this.$refs.Dialog.showToast(variant, textDetail, noAutoHide);
    },
    getTimeFormat(dateTime) {
      return moment(dateTime).format("HH:mm");
    },
    inClinic() {
      if (this.lastFcAppointmentSelected) {
        let statusId = this.lastFcAppointmentSelected.extendedProps.statusId;
        return [5, 6, 7].includes(statusId) ? true : false;
      } else {
        return false;
      }
    },
    inTreatmentQueueStatus() {
      if (this.lastFcAppointmentSelected) {
        return this.lastFcAppointmentSelected.extendedProps.statusId == 5
          ? true
          : false;
      } else {
        return false;
      }
    },
    inPaymentQueueStatus() {
      if (this.lastFcAppointmentSelected) {
        return this.lastFcAppointmentSelected.extendedProps.statusId == 6
          ? true
          : false;
      } else {
        return false;
      }
    },
    inPaidStatus() {
      // if (this.lastFcAppointmentSelected) {
      return [7, 11].includes(
        this.lastFcAppointmentSelected?.extendedProps.statusId
      )
        ? true
        : false;
      // } else {
      //   return false;
      // }
    },
    setBgColor() {
      this.tablePaymentList.forEach(item => {
        let diff = moment().diff(item.treatmentTime, "minutes");
        if (diff <= 2) {
          item._rowVariant = "green";
        } else if (diff <= 5) {
          item._rowVariant = "lightGreen";
        } else if (diff <= 10) {
          item._rowVariant = "yellow";
        } else if (diff <= 12) {
          item._rowVariant = "lightRed";
        } else if (diff <= 15) {
          item._rowVariant = "red";
        } else {
          item._rowVariant = "purple";
        }
      });
    },
    findStatusName(statusId) {
      return this.getAppointmentStatusList.find(function (item) {
        return item["code"] == statusId;
      });
    },
    findReminderObj(rmdId) {
      return this.getReminderList.find(function (item) {
        return item["val"] == rmdId;
      });
    },
    fcGotoDate(date) {
      this.$refs.calendarRoom.getApi().gotoDate(date);
      this.$refs.calendarDoctor.getApi().gotoDate(date);
    },
    fcScrolltoTime(time) {
      this.$refs.calendarRoom.getApi().scrollToTime(time);
      this.$refs.calendarDoctor.getApi().scrollToTime(time);
    },
    async fcUpdateSize() {
      await this.$nextTick();
      await this.$nextTick();
      this.$refs.calendarRoom.getApi().rerenderEvents();
      this.$refs.calendarDoctor.getApi().rerenderEvents();
      this.$refs.calendarRoom.getApi().updateSize();
      this.$refs.calendarDoctor.getApi().updateSize();
    },

    //order -1 ซ่อน, 0 default (ในกรณีที่สร้างใหม่) ,1 2 3 ... ลำดับปกติ
    assignDoctorsList() {
      this.doctorsList = JSON.parse(localStorage.getItem("displayDoctors"));
    },
    blinkMe(e) {
      let index = this.tablePaymentList.findIndex(i => i.id == e.id);
      if (index == -1) return;
      let item = this.tablePaymentList[index];
      item.blink = true;
      this.$set(this.tablePaymentList, index, item);

      setTimeout(() => {
        let index = this.tablePaymentList.findIndex(i => i.id == e.id);
        if (index == -1) return;
        let item = this.tablePaymentList[index];
        item.blink = false;
        this.$set(this.tablePaymentList, index, item);
      }, 6000);
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      return item.blink ? "blink" : "";
    },
    assignBlinkAptId(id) {
      this.blinkAptId = id;
      setTimeout(() => (this.blinkAptId = null), 3000);
    },
    async createEmptyTreatment(appointment) {
      let treatment = {
        // id: null,
        appointmentId: appointment.id,
        items: [],
      };
      await this.$store
        .dispatch("moduleTreatment/createTreatment", {
          clinicUrl: this.$route.params.clinicUrl,
          branchUrl: this.$route.params.branchUrl,
          event: treatment,
        })
        .then(async res => {
          // if (res.status == 200) {
          // }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    calAge(dateString) {
      if (!dateString) return;
      const date = moment(dateString, "YYYY-MM-DD");
      return moment().diff(date, "years");
    },
    formatDateTime(date) {
      if (!date) return "N/A";
      return moment(date)?.locale("th").format("lll") || "N/A";
    },
    checkRruleIfSameDay(rruleStr) {
      let rule = RRule.fromString(rruleStr);
      let momentDateDefault = moment(this.dateDefault, "YYYY-MM-DD");

      let y = momentDateDefault.year();
      let m = momentDateDefault.month() + 1;
      let d = momentDateDefault.date();
      let checkDate = datetime(y, m, d);

      let nextOccurrence = rule.after(checkDate, true); // next rule date including today
      let match = moment(nextOccurrence).isSame(momentDateDefault, "day"); // check if 'DAY' is same

      return match;
    },
    async changeTab() {
      this.saveTabIndex();
      this.fcUpdateSize();
    },
    async saveTabIndex() {
      await this.$nextTick();
      localStorage.setItem("queueManangeTabIndex", this.tabIndex);
    },
    handlePaotangRowClicked(e) {
      this.lastAppointmentId = e.id;
      if (e.statusId === 11) {
        this.openDialogCancelPaotangPayment(e);
        return;
      }
      if (e.statusId === 12) {
        this.openPaotangPrintingReceipt(e);
        return;
      }
    },
    async openDialogCancelPaotangPayment(e) {
      this.isLoading = true;
      const { data } = await this.checkCreateReceipt({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        appointmentId: e.id,
      });
      this.isLoading = false;
      this.$refs.Dialog.showAlertConfirm(
        "ยกเลิกชำระเงินด้วยเป๋าตัง?",
        "คุณต้องการยกเลิกชำระเงินด้วยเป๋าตัง",
        null,
        "ใช่",
        "ไม่"
      ).then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.$refs.verifyModalQueueManage.show(data.id);
        }
      });
    },

    openPaotangPrintingReceipt(e) {
      //open reciept preview modal with

      //** ดึง receipt จาก appointment id ได้มั้ย
      this.lastAppointmentId = e.id;

      //fecth recipt
      const receiptPromise = this.checkCreateReceipt({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        appointmentId: e.id,
      });

      //fetch patient
      const patientPromise = this.fetchPatientById({
        clinicUrl: this.$route.params.clinicUrl,
        branchUrl: this.$route.params.branchUrl,
        id: e.patientId,
      });

      this.isLoading = true;
      Promise.all([receiptPromise, patientPromise])
        .then(responseAll => {
          if (responseAll) {
            const [receiptRes, patientRes] = responseAll;

            if (receiptRes.status == 200 && patientRes.status == 200) {
              if (receiptRes.data.id && patientRes.data.id) {
                this.$refs.paymentReceiptPreviewInQueueManage.showOriginalReceipt(
                  receiptRes.data,
                  patientRes.data
                );
              } else {
                eventBus.$emit("alertSwal", {
                  title: "ระบบขัดข้อง",
                  message: "ไม่สามารถแสดงข้อมูลใบเสร็จได้",
                  icon: "error",
                });
              }
            }
          }
        })
        .catch(err => {
          console.error("catch:", err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  async mounted() {
    this.onJQuery();

    eventBus.$on("openCustomerModal", () => {
      this.$refs.customerModal.show();
    });
    eventBus.$on("openSearchCustomerModal", () => {
      this.$refs.searchCustomerModal.show();
    });
    eventBus.$on("openProfileCustomerModal", (appointment = null, patient) => {
      this.openProfileCustomerModal(appointment, patient);
    });
    eventBus.$on("openCheckInModal", appointment => {
      this.openCheckInModal(null, appointment);
    });
    eventBus.$on("openPaymentHistoryModal", appointment => {
      this.openPaymentHistoryModal(appointment);
    });
    eventBus.$on("openPaymentModal", async appointment => {
      await this.createEmptyTreatment(appointment);
      this.openPaymentModal(appointment);
    });

    eventBus.$on("blinkNewPayment", this.blinkMe);
    this.interval = setInterval(() => this.setBgColor(), 1000);
    this.$nextTick(() => {
      // this.tabIndex =
      //   parseFloat(localStorage.getItem("queueManangeTabIndex")) || 0;
      this.fcUpdateSize();
    });
    eventBus.$on("fetch-queue-data", async () => {
      await this.$nextTick();
      await this.$nextTick();
      this.getAppointmentListByDate(this.dateDefault, true);
    });
    eventBus.$on("blinkAptId", this.assignBlinkAptId);
    eventBus.$on("goToDateTime", this.goToDateTime);
    await this.$nextTick();
    this.tabIndex = parseInt(localStorage.getItem("queueManangeTabIndex")) || 0;
  },
  updated() {
    this.setBgColor();
  },
  beforeDestroy() {},
  destroyed() {
    this.offJQuery();
    this.hidePopOver();

    eventBus.$off("openCustomerModal");
    eventBus.$off("openSearchCustomerModal");
    eventBus.$off("openProfileCustomerModal");
    eventBus.$off("openCheckInModal");
    eventBus.$off("openPaymentHistoryModal");
    eventBus.$off("openPaymentModal");
    eventBus.$off("blinkNewPayment");
    eventBus.$off("fetch-queue-data");

    eventBus.$off("blinkAptId");
    eventBus.$off("goToDateTime");

    clearInterval(this.interval);
  },
};
</script>

<style></style>
